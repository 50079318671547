import { client } from './client';

const BASE_URL = '/users';

export const usersService = {
  login: (form: { email: string; password: string }) =>
    client.post(`${BASE_URL}/login`, form),
  register: (form: { email: string; name: string; password: string }) =>
    client.post(`${BASE_URL}`, form),
  current: () => client.get(`${BASE_URL}/current`),
};
