export const routes = {
  Home: '/home',
  Menu: '/menu',
  EditMenu: '/edit-menu',
  Food: '/food',
  Library: '/library',
  Book: '/book',
  EditBook: '/edit-book',
  Auth: '/auth',
  Reservation: '/reservation',
  ReservationList: '/reservation-list',
};
