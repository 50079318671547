import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../../providers/userProvider';
import { routes } from '../../routes';

type Props = {
  children: React.ReactElement;
};

export const ProtectedRouter: React.FC<Props> = ({ children }) => {
  const { user, isLoading } = useUser();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!user || user.role !== 'ADMIN') {
    return <Navigate to={routes.Home} state={{ from: location }} replace />;
  }

  return children;
};
