import { booksService } from './books';
import { foodService } from './food';
import { reservationsService } from './reservations';
import { usersService } from './user';

// export const BASE_URL = 'http://localhost:8080';
export const BASE_URL = 'http://muffinwithcat.online/api/v1';

export const api = {
  booksService,
  foodService,
  reservationsService,
  usersService,
};
