import { Suspense, lazy, useEffect } from 'react';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import { routes } from './routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer } from 'react-toastify';

import { api } from './api';
import { useUser } from './providers/userProvider';
import { User } from './types';
import { setToken } from './utils/token';
import { ProtectedRouter } from './components/ProtectedRouter';

const Home = lazy(() => import('./pages/Home'));
const Menu = lazy(() => import('./pages/Menu'));
const Food = lazy(() => import('./pages/Food'));
const FoodEdit = lazy(() => import('./pages/FoodEdit'));
const Library = lazy(() => import('./pages/Library'));
const Book = lazy(() => import('./pages/Book'));
const BookEdit = lazy(() => import('./pages/BookEdit'));
const Auth = lazy(() => import('./pages/Auth'));
const Reservation = lazy(() => import('./pages/Reservation'));
const ReservationList = lazy(() => import('./pages/ReservationList'));
function App() {
  const { user, setUser, setIsLoading } = useUser();

  useEffect(() => {
    setIsLoading(true);

    // const { token, ...user } = {
    //   email: 'asd',
    //   name: 'test name',
    //   role: 'ADMIN',
    //   token:
    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzZEBhc2QuYXNkIiwiaWF0IjoxNzE3NTE0ODA3fQ.LIsF7PmVuDJs9MohSjkn9RDM-bJCax99VsT2Az081H0',
    // };

    // setUser(user as User);
    // setToken(token);
    // setIsLoading(false);

    api.usersService
      .current()
      .then((response) => response.json())
      .then((data) => {
        const { token, ...user } = data;

        if (!token) {
          return;
        }

        setUser(user as User);
        setToken(token);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Suspense fallback={<></>}>
        <Routes>
          <Route
            path={routes.Auth}
            element={user ? <Navigate to={routes.Home} /> : <Auth />}
          />
          <Route path={routes.Home} element={<Home />} />
          <Route path={routes.Menu} element={<Menu />} />
          <Route path={routes.Reservation} element={<Reservation />} />
          <Route path={routes.Library} element={<Library />} />
          <Route path={`${routes.Book}/:id`} element={<Book />} />
          <Route path={`${routes.Food}/:id`} element={<Food />} />

          <Route
            path={`${routes.EditMenu}/:id?`}
            element={
              <ProtectedRouter>
                <FoodEdit />
              </ProtectedRouter>
            }
          />
          <Route
            path={`${routes.EditBook}/:id?`}
            element={
              <ProtectedRouter>
                <BookEdit />
              </ProtectedRouter>
            }
          />
          <Route
            path={routes.ReservationList}
            element={
              <ProtectedRouter>
                <ReservationList />
              </ProtectedRouter>
            }
          />

          <Route path='*' element={<Navigate to={routes.Home} replace />} />
        </Routes>
      </Suspense>
      <ToastContainer />
    </>
  );
}

export default App;
