import { BASE_URL } from '.';
import { getToken } from '../utils/token';

export const client = {
  get(url: string) {
    const token = getToken();

    return fetch(`${BASE_URL}${url}`, {
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
  },
  post(url: string, body?: Record<string, unknown>) {
    const token = getToken();

    return fetch(`${BASE_URL}${url}`, {
      method: 'POST',
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  },
  patch(url: string, body?: Record<string, unknown>) {
    const token = getToken();

    return fetch(`${BASE_URL}${url}`, {
      method: 'PATCH',
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  },
  delete(url: string) {
    const token = getToken();

    return fetch(`${BASE_URL}${url}`, {
      method: 'DELETE',
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  },
};
