import { Food } from '../types';
import { client } from './client';

const BASE_URL = '/food';

export const foodService = {
  getMenu: () => client.get(`${BASE_URL}/menu`),
  getFood: (id: number) => client.get(`${BASE_URL}/${id}`),
  changeFood: (id: number, newData: Partial<Food>) =>
    client.patch(`${BASE_URL}/${id}`, newData),
  createFood: (newData: Omit<Food, 'id'>) =>
    client.post(`${BASE_URL}`, newData),
  deleteFood: (id: number) => client.delete(`${BASE_URL}/${id}`),
};
