import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { User } from '../../types';

export const UserContext = createContext<{
  user?: User;
  setUser: (newUser?: User) => void;
  isLoading: boolean;
  setIsLoading: (newValue: boolean) => void;
}>({ isLoading: true, setUser: () => {}, setIsLoading: () => {} });

export const useUser = () => useContext(UserContext);

type Props = PropsWithChildren;

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(true);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
