import { Book } from '../types';
import { client } from './client';

const BASE_URL = '/books';

export const booksService = {
  getBooks: () => client.get(`${BASE_URL}`),
  getBook: (id: number) => client.get(`${BASE_URL}/${id}`),
  changeBook: (id: number, newData: Partial<Book>) =>
    client.patch(`${BASE_URL}/${id}`, newData),
  createBook: (newData: Omit<Book, 'id'>) =>
    client.post(`${BASE_URL}`, newData),
  deleteBook: (id: number) => client.delete(`${BASE_URL}/${id}`),
};
